
.display-option-container {
  background-color: white;
  width: fit-content;
  height: fit-content;
}

.display-option-table > thead > tr > th {
  background-color: #5d7c9f;
  color: white;
  font-size: smaller;
  font-weight: 600;
  padding: 5px 10px;
  text-align: center;
}

.display-option-table > tbody > tr > td {
  padding: 5px;
  width: fit-content;
}

.display-option-table > tbody > tr.even-row {
  background-color: #e8e7e7;
}
.display-option-table > tbody > tr.odd-row {
  background-color: #d1cece;
}




.display-option-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #ebe9e9;
}
.display-option-btn > div {
  padding: 4px 10px;
  width: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid #dddcdc;
  border-radius: 5px;
  transition: 0.25s;
  margin: 6px 0 3px 0;
}

.add-config {
  color: #318aec;
  background-color: white;
}

.add-config:hover {
  background-color: lightgray;
}

.save-config {
  background-color: #318aec;
  color: white;
}

.save-config:hover {
  background-color: lightgray;
  color: #318aec;
}

.coppy-config {
  background-color: white;
}

.coppy-config:hover {
  background-color: lightgray;
}

.delete-config {
  text-align: center;
  color: #f13838;
  cursor: pointer;
}

.delete-config:hover {
  color: gray;
}