.nav-link {
  margin: 2px 0px;
  transition: 0.25s;
  border-radius: 5px;
  overflow: hidden;
}
.nav-link:hover {
  background-color: #5d5d62;
}
.focus {
  background-color: #348eed;
}
.nav-link:hover {
  text-decoration: none;
}

/*info100*/
.nav-link.info100 {
  background-color: transparent;
}
.nav-link.info100:hover {
  background-color: #5d5d62;
}
.focus.info100 {
  background-color: #348eed;
}
.nav-link.info100 > .nav-btn {
  color: white;
}

/*info*/
.nav-link.info {
  background-color: transparent;
}
.focus.info {
  background-color: #348eed;
}
.nav-link.info > .nav-btn {
  font-weight: 500;
  color: black;
}
.nav-link.info > .nav-btn:hover {
  color: #dc3545;
}

.nav-btn {
  display: flex;
  align-items: center;
  justify-content: start;
  background-color: transparent;
  width: 100%;
  height: 100%;
  margin: 0px;
  outline: none;
  border: none;
  font-weight: 500;
  color: white;
}
.nav-btn:focus {
  outline: none;
}

/*no link*/
.nav-btn.no-link {
  padding: 8px 16px;
  border-radius: 5px;
  margin: 2px 0px;
}
.nav-btn.no-link.focus {
  background-color: #348eed;
}
.nav-btn.no-link:hover {
  background-color: #5d5d62;
}
