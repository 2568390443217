.product-list-web-con {
  display: none;
}
.product-list-web-con.show {
  display: flex;
  flex-direction: column;
  width: 340px;
  max-width: 80vw;
  max-height: 60vh;
  background-color: white;
  position: absolute;
  top: 45px;
  right: 5px;
  border-radius: 5px;
  border: 1px solid rgb(231, 228, 228);
  font-weight: 500;
  font-size: small;
  padding-left: 4px;
  z-index: 1;
}

.product-list-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-size: 15px;
}
.product-list-component {
  background-color: lightgray;
  width: 330px;
  max-width: calc(80vw - 10px);
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  border-radius: 5px;
  padding: 5px;
}

/*avatar*/
.product-list-avt {
  width: 100px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.product-list-avt img {
  width: 100%;
  object-fit: contain;
}

/*value*/
.product-list-content {
  margin-left: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.product-list-title {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.product-list-value {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.product-list-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.product-list-search {
  margin: 10px 5px 5px 0px;
  border-radius: 5px;
  border: 1px solid rgb(231, 228, 228);
}

.product-list-search-icon {
  position: absolute;
  right: 15px;
  top: 15px;
  color: gray;
}
