.noti-web-con {
  display: none;
}
.noti-web-con.show {
  display: flex;
  flex-direction: column;
  width: 500px;
  max-height: 700px;
  background-color: white;
  position: absolute;
  top: 45px;
  right: -100px;
  border-radius: 5px;
  border: 1px solid rgb(231, 228, 228);
  font-weight: 500;
  font-size: small;
  padding-left: 4px;
}

.notification-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
  font-size: 15px;
}
.noti-component {
  background-color: rgb(231, 228, 228);
  display: flex;
  flex-direction: row;
  width: 490px;
  margin-top: 5px;
  border-radius: 5px;
  padding: 5px;
}
.noti-component.read {
  background-color: rgb(240, 239, 239);
}

/*avatar*/
.noti-avt {
  width: 56px;
  height: 56px;
  background-color: white;
  margin: 5px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
}
.noti-avt img {
  width: 70px;
  height: 70px;
}

/*value*/
.noti-content {
  width: 410px;
  margin-left: 5px;
  display: flex;
  flex: 1;
  flex-direction: column;
}
.noti-title {
  width: 100%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.noti-value {
  width: 95%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.noti-main {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.noti-tick {
  margin-left: 5px;
  margin-right: 5px;
  color: rgb(231, 228, 228);
  /* z-index: 1; */
}
.noti-tick.blue {
  margin-left: 5px;
  margin-right: 5px;
  color: #0090ed;
}

.noti-tick-all-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.noti-tick-all {
  width: fit-content;
  color: #0090ed;
  margin-right: 5px;
}

/*btn*/
.noti-btn-con {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.noti-btn.blue {
  background-color: #2e89ff;
  color: white;
  margin: 5px;
  border-radius: 5px;
}

.infinite-scr-con {
  overflow: auto;
  display: flex;
  flex-direction: column;
}
