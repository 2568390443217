.header-confirm-popup {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  width: 100%;
  padding: 2px 2px 2px 10px;
  background-color: #256cb8;
  color: white;
  font-weight: 500;
}

.confirm-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  width: 100px;
  height: 35px;
  cursor: pointer;
  z-index: inherit;
  border-radius: 5px;
  font-size: 15px;
  margin: 0 10px;
}
.confirm-button:focus {
  border: none;
  outline: none;
}
.confirm-button:active {
  transform: translateY(2px);
}
