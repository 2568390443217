/*register button*/
.register-btn {
  cursor: pointer;
  background-color: #ff000a;
  color: white;
  font-weight: 600;
  font-size: 15px;
  border-radius: 10px;
  box-shadow: 4px 4px 5px gray;
  width: calc(100vw - 20px);
  max-width: 326px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 8px 0px;
  position: relative;
  overflow: hidden;
}
.register-btn::before {
  content: '';
  background-color: #d4000d;
  border-radius: 8px 8px 8px 0px;
  top: 0px;
  left: -100%;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: 0.5s;
}
.register-btn:hover::before {
  top: 0px;
  left: 0;
}
.register-btn::after {
  content: 'Đăng ký';
  border-radius: 8px 8px 8px 0px;
  top: 0px;
  left: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/*text*/
.text-btn-login-container.center {
  justify-content: center;
  color: gray;
}
