.toast-body {
  max-width: 500px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-height: 4em;
  line-height: 1.5em;
}

.cancel-notification {
  z-index: 2;
}
