.bigsize-image-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.btn-nav-image {
  z-index: 1;
  color: red;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  cursor: pointer;
  transform: scale(1.75);
  transition: 0.5s;
  opacity: 0.7;
}
.btn-nav-image:hover {
  background-color: rgb(128, 128, 128, 0.5);
  opacity: 1;
}
.btn-nav-image.next {
  right: -20px;
}
.btn-nav-image.back {
  left: -20px;
}

.image-child {
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: max(15vw, 25vh);
  height: max(15vw, 25vh);
  transition: 0.5s;
}
.image-child.center img {
  object-fit: contain;
  cursor: pointer;
  opacity: 1;
  width: 100%;
  height: 100%;
}
.image-child.center > div:nth-child(2) {
  width: 100%;
  height: 100%;
}
.image-child.center.full-size .btn-delete-image {
  display: none;
}
.image-child.center .btn-zoom-out-image {
  display: none;
}
.image-child.center.full-size .btn-zoom-out-image {
  display: block;
}
.image-child:hover {
  border: none;
  background-color: #cfdcea;
}
.image-child.center:hover {
  border: none;
  background-color: #cfdcea;
}

.image-child img {
  opacity: 0.3;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-child.full-size {
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 100vh;
  transform: translate(-50%, -50%);
  position: fixed;
  opacity: 1;
  background-color: white;
  z-index: 150;
}

.btn-delete-image {
  top: 0px;
  right: 0px;
  width: fit-content !important;
  height: fit-content !important;
  color: red;
  position: absolute;
  cursor: pointer;
  transition: 0.5s;
}
.btn-delete-image:hover {
  background-color: rgb(128, 128, 128, 0.5);
  color: red;
}

.btn-zoom-out-image {
  position: absolute;
  top: 25px;
  right: 25px;
  color: red;
  cursor: pointer;
  transition: 0.5s;
  z-index: 1;
}

.thumb-image-child {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  width: 50px;
  height: 50px;
  filter: opacity(0.5);
  margin: 2px 5px;
}
.thumb-image-child.focus-img {
  filter: opacity(1);
  background-color: #717171;
}
.thumb-image-child img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.btn-image-upload {
  margin: 10px;
  padding: 5px 20px;
  background-color: #256cb8;
  color: white;
  vertical-align: middle;
}
.image-upload-label {
  vertical-align: middle;
}
.row-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.image-url {
  width: fit-content;
  margin: 5px auto 15px auto;
  display: flex;
  flex-direction: row;
}
.copy-url-btn {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-left: 5px;
  cursor: pointer;
}
.copy-url-btn :hover {
  background-color: lightgray;
}
.copy-url-icon {
  margin: auto 0;
}
