.login-input {
  height: 40px;
  margin-bottom: 10px;
  border-bottom: 1px solid lightgray;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
}

.login-input.error {
  margin-bottom: 5px !important;
  border-bottom: 1px solid #ed4d2d !important;
}
.login-input > i {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.4);
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.login-input > input {
  border: none;
  display: flex;
  flex: 1;
  margin: 0px 10px;
  background: white;
}

.login-form-error-text {
  color: #ed4d2d;
  font-size: 13px;
  line-height: 1;
  margin-bottom: 5px;
  padding: 0px 5px;
}
