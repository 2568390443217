.verify-title {
  color: #42494d;
  font-size: max(20px, min(calc((100vw - 991px) * 9999), 30px));
  font-weight: 700;
}

/*verify form*/
.verify-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*verify input*/
.verify-input {
  position: relative;
  margin-top: 25px;
  width: calc(100vw - 20px);
  max-width: 326px;
  border: 2px solid lightgray;
  border-radius: 8px 8px 8px 0px;
}
.verify-input > input {
  width: 100%;
  height: max(40px, min(calc((100vw - 991px) * 9999), 40px));
  padding-left: 10px;
  opacity: 0;
  border: none;
  font-weight: 600;
  border-radius: 8px 8px 8px 0px;
}
.verify-input.focus-input {
  border-color: #009250;
}
.verify-input-title {
  position: absolute;
  top: 20%;
  left: 20px;
  background-color: #fbfbfb;
  translate: 1s;
  color: #42494d;
  font-size: 18px;
  font-weight: 500;
  color: lightgray;
  transition: 0.5s;
}
.verify-input.focus-input .verify-input-title {
  top: -35%;
  color: #42494d;
}
.verify-input.focus-input > input {
  opacity: 1;
}
.verify-input.validate-input > input {
  opacity: 1;
}
.verify-input.validate-input .verify-input-title {
  top: -35%;
  color: #42494d;
}

/*verify button*/
.verify-btn {
  margin-top: 27px;
  cursor: pointer;
  background-color: #ff000a;
  color: white;
  font-weight: 600;
  font-size: 15px;
  border-radius: 10px;
  box-shadow: 4px 4px 5px gray;
  width: calc(100vw - 20px);
  max-width: 326px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 8px 8px 8px 0px;
  position: relative;
  overflow: hidden;
}
.verify-btn::before {
  content: '';
  background-color: #d4000d;
  border-radius: 8px 8px 8px 0px;
  top: 0px;
  left: -100%;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: 0.5s;
}
.verify-btn:hover::before {
  top: 0px;
  left: 0;
}
.verify-btn::after {
  content: 'Đổi mật khẩu';
  border-radius: 8px 8px 8px 0px;
  top: 0px;
  left: 0px;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/*text btn verify*/
.text-btn-verify-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: calc(100vw - 20px);
  max-width: 326px;
  color: #ff000a;
  font-size: 16px;
  font-weight: 600;
  height: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.text-btn-verify-container > div {
  cursor: pointer;
}

/*confirm*/
.verify-confirm-text-container {
  width: calc(100vw - 20px);
  max-width: 326px;
  margin-top: 15px;
  font-size: 15px;
  font-weight: 500;
}
