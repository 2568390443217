.qrcode-reader-content {
  background-color: black;
  overflow: auto;
  padding: 10px;
}

.switch-camera {
  position: absolute;
  bottom: 26px;
  background: rgb(29, 29, 29);
  opacity: 0.6;
  left: calc(50% - 30px);
  width: 48px;
  height: 48px;
  border-radius: 24px;
}

.switch-camera > span {
  color: white;
  opacity: 1;
  margin: 8px;
  font-size: xx-large;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}
