.fullsize-image-container {
  width: 90vw;
  height: 90vh;
  background-color: black;
  padding: 5vh 5vw;
}

.fullsize-image-close {
  top: 10px;
  right: 10px;
  position: absolute;
  color: gray;
  font-size: 30px;
  cursor: pointer;
}

.fullsize-image {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  object-fit: contain;
}

.full-size-image-dot-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: absolute;
  bottom: 2vh;
  left: 50%;
  transform: translate(-50%);
}
.full-size-image-dot {
  width: 10px;
  height: 10px;
  background-color: lightgray;
  border-radius: 50%;
  bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
}
.full-size-image-dot.show {
  background-color: red;
}

.full-size-btn-nav-image {
  font-size: 30px;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  color: gray;
  transform: translate(0, -50%);
  z-index: inherit;
  cursor: pointer;
  transition: 0.25s;
  border-radius: 50%;
}

.full-size-btn-close-image:hover,
.full-size-btn-nav-image:hover,
.full-size-btn-close:active,
.full-size-btn-nav-image:active {
  color: rgb(220, 53, 69);
}

.full-size-btn-nav-image.back {
  left: 2vw;
}
.full-size-btn-nav-image.next {
  right: 2vw;
}
