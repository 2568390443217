::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.chat-content {
  overflow: auto;
  height: 368px;
  display: flex;
  flex-direction: column-reverse;
}
.adiv {
  background: #0f424d;
  border-radius: 15px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  font-size: 12px;
  height: 46px;
  font-weight: 500;
}
.chat {
  border: none;
  background: #e2ffe8;
  font-size: 10px;
  border-radius: 20px;
  width: 240px;
}
.bg-white {
  border: 1px solid #e7e7e9;
  font-size: 10px;
  border-radius: 20px;
  width: 240px;
}
.media img {
  border-radius: 20px;
}
.dot {
  font-weight: bold;
}
.form-group {
  position: absolute;
  bottom: 10px;
  width: 280px;
  height: 72px;
  border-radius: 12px;
  border: 1px solid #d1cccc;
  margin-left: 10px;
  margin-bottom: 0px !important;
}
.form-control {
  border-radius: 12px;
  border: 0px;
  font-size: 12px;
  width: 240px;
  resize: none;
}
.sent-btn {
  position: absolute;
  bottom: 40px;
  right: 20px;
  color: #0f424d;
  cursor: pointer;
}
.form-control:focus {
  box-shadow: none;
}
.form-control::placeholder {
  font-size: 12px;
  color: #c4c4c4;
}
.sent-message {
  flex-direction: row-reverse !important;
}

.btn-icon {
  cursor: pointer;
  margin: 5px;
}

.avatar-collapse {
  width: 100%;
  cursor: pointer;
}

.room-name {
  width: 80%;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
