.import-excel-container {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: white;
}
.import-excel-info {
  margin-bottom: 10px;
}
.btn-import {
  background-color: #256cb8;
  border: none;
  outline: none;
  color: white;
  font-weight: 500;
  width: 100%;
  margin: 5px auto 5px auto;
}

.btn-import:focus {
  border: none;
  outline: none;
}

.btn-import:active {
  transform: translateY(1px);
}

.error-label {
  color: 'red';
  padding-left: 2px;
}