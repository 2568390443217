.print-item {
  position: relative;
}

.print-content {
  background: white;
  margin-bottom: 2px;
}

.print-button {
  position: absolute;
  background-color: darkgray;
  border: 0px;
  border-radius: 5px;
  padding: 5px;
  top: 20px;
  right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0a4687;
}

.icon-print {
  padding-right: 5px;
  color: #0a4687;
}
